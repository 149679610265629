import React from 'react';
import { IconPlainLink } from 'components/global/Header/styles';
import { Profile as ProfileIcon } from 'components/icons';
import { useFeatureFlag } from 'utils/featureFlags';
import { useUser } from '@auth0/nextjs-auth0';
import { Wrapper, Avatar, ProfileIconButton } from './styles';

const Profile = () => {
  const { user, isLoading } = useUser();

  const enabled = useFeatureFlag('login_button');

  if (!enabled) {
    return null;
  }

  return (
    <Wrapper>
      {isLoading ? null : user ? (
        <ProfileIconButton
          aria-label="Log out"
          onClick={() => {
            if (confirm('Would you like to log out?')) {
              window.location.href = '/api/auth/logout';
            }
          }}
        >
          {user.picture ? <Avatar src={user.picture} /> : <ProfileIcon />}
        </ProfileIconButton>
      ) : (
        <IconPlainLink href="/api/auth/login" aria-label="Log in">
          <ProfileIconButton as="div" data-testid="login">
            <ProfileIcon />
          </ProfileIconButton>
        </IconPlainLink>
      )}
    </Wrapper>
  );
};

export default Profile;
