import React from 'react';
import SiteLink from 'components/Link';
import Container from 'components/layout/Container';
import ResponsiveBox from 'components/layout/ResponsiveBox';
import {
  StyledContainer,
  JargonLabel,
  StyledLogo,
  DarkContainer,
  DarkFooterRow,
  DarkFooterCol,
  TinyLogo,
  DarkWrapper,
  Wrapper,
  ColTitle,
  FlexCol,
  FlexRow,
  JargonLabelMeaning,
} from './styles';
import MailingListSignup from './MailingListSignup';

export interface FooterLinkProps {
  _key: string;
  _type: 'link';
  path?: string;
  title?: string;
}

export interface FooterLinkColumnProps {
  _key: string;
  _type: 'linkColumn';
  title?: string;
  links?: FooterLinkProps[];
}

interface FooterProps {
  linkColumns?: FooterLinkColumnProps[];
  newsletterColumn?: {
    title?: string;
    body?: string;
  };
}

export default function Footer({
  linkColumns = [],
  newsletterColumn,
}: FooterProps) {
  return (
    <Wrapper>
      <StyledContainer noPadding>
        <FlexRow>
          <FlexCol width={400}>
            <JargonLabel>
              Sproutl; [sprəʊt-l] <JargonLabelMeaning>verb</JargonLabelMeaning>
            </JargonLabel>
            <p>To plant something.</p>
            <p>
              Who cares if you`ve never picked up a trowel before? This is
              gardening made easy. All you need to do is plant something.
            </p>
          </FlexCol>
          <FlexRow noCollapse>
            {linkColumns.map((column) => (
              <FlexCol width={220} key={column._key}>
                <ColTitle>{column.title}</ColTitle>
                {column?.links?.map((link) => (
                  <div key={link._key}>
                    <SiteLink
                      href={link.path || '/'}
                      title={link.title}
                      variant="secondary"
                    >
                      {link.title}
                    </SiteLink>
                  </div>
                ))}
              </FlexCol>
            ))}
          </FlexRow>
          <FlexCol width={450}>
            <ColTitle>{newsletterColumn?.title}</ColTitle>
            <div>{newsletterColumn?.body}</div>
            <MailingListSignup />
          </FlexCol>
        </FlexRow>
      </StyledContainer>
      <Container>
        <ResponsiveBox aspectRatio={0.12}>
          <StyledLogo />
        </ResponsiveBox>
      </Container>
      <DarkWrapper>
        <DarkContainer>
          <DarkFooterRow>
            <DarkFooterCol>
              <TinyLogo />
            </DarkFooterCol>
            <DarkFooterCol>
              © {new Date().getFullYear()} Sproutl. All Rights Reserved.
            </DarkFooterCol>
          </DarkFooterRow>
        </DarkContainer>
      </DarkWrapper>
    </Wrapper>
  );
}
