import styled from 'styled-components';
import { BaseModalBackground } from 'styled-react-modal';
import theme from 'utils/theme';
import { mobile, tablet } from 'utils/media';

export const StickyGlobalHeader = styled.header`
  position: relative;
  z-index: ${theme.zIndexes.header};
  position: sticky;
  top: 0;
`;

export const HeaderAlertContainer = styled.div`
  ${mobile} {
    z-index: ${theme.zIndexes.normal};
  }

  ${tablet} {
    z-index: ${theme.zIndexes.header + 1};
  }
`;

export const SpecialModalBackground = styled(BaseModalBackground)`
  transition: all 400ms ease-in-out;
  opacity: ${(props) => props.opacity};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${theme.zIndexes.header};
  background-color: #00000050;
  will-change: opacity;
`;
