import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Logo from 'components/Logo';
import { Hamburger } from 'components/icons';
import SiteLink from 'components/Link';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import MegaNavDesktop from './components/MegaNav/Desktop';
import { NavRoot, NavSection } from './components/MegaNav/types';
import Search from './components/Search';
import Basket from './components/Basket';
import Profile from './components/Profile';
import {
  LogoLink,
  StyledContainer,
  Background,
  HamburgerTempWrapper,
  IconsWrapper,
  HeaderNavLinks,
  RelativeWrapper,
  LogoWrapper,
} from './styles';
import MegaNavMobile from './components/MegaNav/Mobile';

interface HeaderProps {
  nav: NavRoot;
}

export default function Header({ nav }: HeaderProps) {
  const router = useRouter();
  /*
   * On Mobile the state is true/false depending on whether the user has clicked the Menu hamburger icon.
   * On Desktop, the state refers to the indidivual NavSection the user has hovered over.
   */
  const [navState, setNavState] = useState<NavSection | boolean>(false);

  const isActive = !!navState;

  useEffect(() => {
    if (!navState) {
      (document.activeElement as HTMLElement)?.blur();
    }
  }, [navState]);

  useEffect(() => {
    const handleRouteEnd = () => {
      setNavState(false);
    };

    router.events.on('routeChangeComplete', handleRouteEnd);

    return () => {
      router.events.off('routeChangeComplete', handleRouteEnd);
    };
  }, [router.events]);

  return (
    <RelativeWrapper>
      <Background data-testid="header" isActive={isActive}>
        <StyledContainer>
          <LogoWrapper>
            <Link href="/" passHref>
              <LogoLink isActive={isActive}>
                <Logo />
                Sproutl
              </LogoLink>
            </Link>
          </LogoWrapper>
          <HeaderNavLinks>
            {nav.map((section, index) => (
              <li key={index}>
                <SiteLink
                  variant="secondary"
                  href={section.path || '#'}
                  onMouseOver={() => {
                    setNavState(section);
                  }}
                  active={navState && navState === section}
                >
                  {section.title}
                </SiteLink>
              </li>
            ))}
          </HeaderNavLinks>
          <IconsWrapper>
            <Search />
            <Basket />
            <Profile />
          </IconsWrapper>
          <HamburgerTempWrapper
            onClick={() => {
              setNavState(true);
            }}
            aria-label="Menu"
          >
            <Hamburger />
          </HamburgerTempWrapper>
        </StyledContainer>
      </Background>
      <AnimatePresence>
        {navState ? (
          typeof navState === 'boolean' ? (
            <MegaNavMobile nav={nav} onClose={() => setNavState(false)} />
          ) : (
            <MegaNavDesktop
              section={navState}
              onClose={() => setNavState(false)}
            />
          )
        ) : null}
      </AnimatePresence>
    </RelativeWrapper>
  );
}
